import React, { useContext } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Button from '../../../../../components/287634/button/button';
import { LangContext } from '../../../../../context/lang.context';
import { cuitFormatter } from '../../../../../utils/formatters';
import { CompanyContext } from '../../../../../context/company.context';
import { IResponse } from '../../../vu-security/models';
import { Stepper } from '../../../../../components/287634/stepper/stepper';
import { H1, H2, H4 } from '../../../../../components/287634/typography';

export const SuccessPage = ({ texts, callback }: { texts: IResponse; callback: () => any }) => {
  const { feedback, action } = texts;
  const { t } = useContext(LangContext);
  const { getPublicURL } = useContext(CompanyContext);
  const data = useStaticQuery(graphql`
    query {
      allI18NJson {
        nodes {
          locale
          clientId
          RETURN_WHATSAPP
          LIVENESS {
            TITLE_6
            TITLE_7
            TITLE_8
            SECOND_STEP {
              BULLET
              BUTTON_3
            }
            ERROR {
              TITLE_10
            }
          }
        }
      }
    }
  `);

  const textByAction = (_action: string | undefined) => {
    const actions: Record<string, string> = {
      ['finished']: t(data).RETURN_WHATSAPP,
      ['redirect']: t(data).LIVENESS.SECOND_STEP.BUTTON_3,
    };
    return (_action && actions[_action]) || t(data).LIVENESS.ERROR.TITLE_10;
  };

  return (
    <section className="flex flex-auto flex-col items-center justify-between text-primary">
      <section className="relative flex w-full flex-col items-center">
        <Stepper step={3} />
        <H2 className="mb-5 mt-[68px] text-center">Validación de identidad</H2>
        <img
          src={getPublicURL(`/theme/assets/images/feedback/generic-success.svg`)}
          className="mt-5 w-2/3"
          alt="Success"
        />
        <H1 className="mx-5 mb-10 mt-10 text-center">
          ¡Tú eres tú!
          <br /> Identidad validada con éxito.
        </H1>
      </section>
      <section className="flex flex-col items-center">
        <Button
          onClick={() => {
            callback();
          }}
        >
          {textByAction(action)}
        </Button>
      </section>
    </section>
  );
};
