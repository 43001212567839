import React, { useContext } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Button from '../../../../../components/287634/button/button';
import { LangContext } from '../../../../../context/lang.context';
import { returnWhatsapp } from '../../../../../utils/browser';
import { SopErrorDefinitions } from '../../../vu-security/models';
import { Stepper } from '../../../../../components/287634/stepper/stepper';
import { H2, P } from '../../../../../components/287634/typography';

interface Props {
  info: SopErrorDefinitions;
  callback: () => void;
}

export const SopErrorPage = ({ callback, info }: Props) => {
  const { title_1, title_2, description_1, description_2, imageSrc } = info;
  const { t } = useContext(LangContext);
  const data = useStaticQuery(graphql`
    query {
      allI18NJson {
        nodes {
          locale
          clientId
          RETRY
          RETURN_WHATSAPP
          LIVENESS_VU {
            SOP {
              MSG {
                DEFAULT_TITLE_3
              }
            }
          }
        }
      }
    }
  `);

  return (
    <section className="flex flex-auto flex-col items-center justify-between text-primary">
      <section className="relative flex flex-col items-center">
        <Stepper step={2} />
        <H2 className="mx-5 mb-5 mt-[68px] text-center">
          {title_1} {title_2 || t(data).LIVENESS_VU.SOP.MSG.DEFAULT_TITLE_3}
        </H2>
        <img className="w-2/3 mb-5" src={imageSrc} alt="Liveness" />
        <P className="mx-10 mb-11 text-center text-red">{description_1}</P>
        <P className="mx-10 mb-11 text-center text-red">{description_2}</P>
      </section>
      <section className="flex flex-col items-center">
        <button
          className="!font-regular mb-4 !appearance-none !border-none bg-transparent !font-body text-base text-primary underline hover:text-gray"
          type="button"
          onClick={() => callback()}
        >
          {t(data).RETRY}
        </button>
        <Button
          className="!font-regular !appearance-none !border-none !font-body"
          type="button"
          onClick={() => {
            returnWhatsapp();
          }}
        >
          {t(data).RETURN_WHATSAPP}
        </Button>
      </section>
    </section>
  );
};
