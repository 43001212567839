import React, { useContext, useEffect, Dispatch, useReducer } from 'react';
import { ActionTypes, ILiveness, IState, TypesOfDispatch, SopErrorType, ErrorType } from '../../vu-security/models';
import pages from '../../../../constants/pages.json';
import creditCardService from './../../../../services/endpoints';
import { navigateToError, redirectWhenStatusPageIsNotValid, navigateToCustomPage } from '../../../../utils/navigate';
import { returnWhatsapp } from './../../../../utils/browser';
import Layout from '../../../../components/287634/layout/layout';
import { ComponentVUSecurity } from '../../vu-security/component-vu-security';
import { Loading } from './../../../../components/287634/loading';
import { SuccessPage } from './succes-page';
import { SopErrorPage } from './error-pages/sop-error-Page';
import { ErrorPage } from './error-pages/error-page';
import { CreditcardContext } from '../../../../context/creditcard.context';
import { diffTwoDatesInSeconds as diff, isTrue, isGreaterThan } from '../../../../utils/functions';
import { useErrorCodeDefinitions } from '../../vu-security/hooks/error-code-definitions-hook';
import { AxiosError, AxiosResponse } from 'axios';
import communicationService from '../../../../services/communication';

const initialState: IState = {
  sopResult: { sopErrorCode: 'genericErrorSop' },
  responseResult: { action: 'pending', feedback: { errorCode: 'genericError' }, url: '' },
  showLoading: false,
  showSuccessPage: false,
  showSopErrorPage: false,
  showFGErrorPage: false,
  loadComponentVuHidden: false,
  showComponentVu: true,
};

const reducer = (state: IState, action: ActionTypes) => {
  const actionsSwitch: Record<TypesOfDispatch, IState> = {
    ON_CLICK_START_INITIAL_PAGE: { ...state, showComponentVu: true},
    ON_CLICK_CONTINUE_SUCCESS_PAGE: { ...state },
    ON_CLICK_RETRY_ERROR_PAGE: {
      ...state,
      showComponentVu: true,
      loadComponentVuHidden: false,
      showSopErrorPage: false,
      sopResult: { sopErrorCode: 'genericErrorSop' },
    },
    ON_SOP_ERROR: {
      ...state,
      showSopErrorPage: true,
      showComponentVu: true,
      loadComponentVuHidden: true,
      sopResult: action.payload,
    },
    ON_SOP_ERROR_RESTART: {
      ...state,
      showSopErrorPage: true,
      showComponentVu: false,
      loadComponentVuHidden: false,
      sopResult: action.payload,
    },
    ON_SOP_COMPLETED: { ...state, showLoading: true, showComponentVu: false, sopCompleted: true },
    ON_RESPONSE_SUCCESS: {
      ...state,
      responseResult: action.payload,
      showComponentVu: false,
      showSuccessPage: true,
      showLoading: false,
    },
    ON_RESPONSE_ERROR: {
      ...state,
      responseResult: action.payload,
      showComponentVu: false,
      showFGErrorPage: true,
      showLoading: false,
    },
  };
  return actionsSwitch[action.type] || state;
};

/* ******************************************************************************* */
export const LivenessVUSecurity = ({ token, render }: any) => {
  /* this hook load errors info */
  const getErrorDefintions = useErrorCodeDefinitions();

  const [state, dispatch]: [IState, Dispatch<ActionTypes>] = useReducer(reducer, initialState);

  const isRestartError = (errorCode: string) =>
    ['endOperation', 'register', 'endOperation_1', 'endOperation_2', 'endOperation_4'].includes(errorCode);

  /* callbacks */
  const onClickStartInitialPage = () => {
    dispatch({ type: 'ON_CLICK_START_INITIAL_PAGE', payload: {} });
  };

  const onClickContinueSuccessPage = () => {
    if (state.responseResult.action === 'redirect') {
      navigateToCustomPage(`/${state.responseResult.url}`, { replace: true })?.();
    } else if (state.responseResult.action === 'finished') {
      returnWhatsapp();
    } else {
      // if action is not recognized, navigate to generic error page as a safety net
      console.warn('Unrecognized action');
      navigateToError()?.();
    }
  };

  const onClickRetryErrorPage = () => {
    if (!isRestartError(state.sopResult.sopErrorCode)) {
      state.sopResult.closeMethod();
    }
    //dispatch({ type: 'ON_CLICK_RETRY_ERROR_PAGE', payload: {} });
    window.location.reload();
  };

  const onSopError = (error: [string, SopErrorType], closeMethod: () => any) => {
    console.info({ 'Sop Error code': error[0] });
    if (isRestartError(error[1])) {
      dispatch({ type: 'ON_SOP_ERROR_RESTART', payload: { sopErrorCode: error[1], closeMethod } });
    } else {
      dispatch({ type: 'ON_SOP_ERROR', payload: { sopErrorCode: error[1], closeMethod } });
    }
  };

  const responseAction = (response: AxiosResponse) => {
    const actions: Record<string, ActionTypes> = {
      redirect: { type: 'ON_RESPONSE_SUCCESS', payload: response.data },
      finished: { type: 'ON_RESPONSE_SUCCESS', payload: response.data },
      custom_error: { type: 'ON_RESPONSE_ERROR', payload: { errorCode: response.data.feedback.error_code } },
      generic_error: { type: 'ON_RESPONSE_ERROR', payload: { errorCode: 'genericError' } },
    };
    dispatch(actions[response.data.action] || actions.generic_error);
  };

  const onSopCompleted = (sopResult: ILiveness) => {
    dispatch({ type: 'ON_SOP_COMPLETED', payload: {} });
    const { livenessVariant, multiData } = sopResult;
    const { operationId, userName } = multiData;
    creditCardService
      .postLiveness(
        {
          liveness_variant: livenessVariant,
          multi_data: {
            operation_id: operationId,
            user_name: userName,
          },
        },
        token
      )
      .then((response: AxiosResponse) => {
        communicationService
          .postCommunicationToken({ token: response.data?.communication_token, text: response.data?.intent })
          .catch(error => {
            console.log(error);
          });
        return responseAction(response);
      })
      .catch(({ errorCode, response }: { errorCode: ErrorType; response: AxiosError | any }) => {
        const error: ErrorType = errorCode || response?.data?.feedback?.error_code;
        if (error) {
          return dispatch({ type: 'ON_RESPONSE_ERROR', payload: { errorCode: error } });
        }
        return dispatch({ type: 'ON_RESPONSE_ERROR', payload: { errorCode: 'genericError' } });
      });
  };

  if (!render || state.showLoading) {
    // show loading component with text only after VU SOP (Liveness) is completed
    return <Loading text={`${state.sopCompleted ? 'Procesando...' : ''}`} />;
  }

  return (
    <>
      {state.showComponentVu && (
        <ComponentVUSecurity
          isHidden={state.loadComponentVuHidden}
          onSopCompleted={onSopCompleted}
          onSopError={onSopError}
        />
      )}
      {(state.loadComponentVuHidden || !state.showComponentVu) && (
        <Layout>
          {state.showSopErrorPage && (
            <SopErrorPage
              callback={onClickRetryErrorPage}
              info={getErrorDefintions(state.sopResult.sopErrorCode || 'genericErrorSop')}
            />
          )}
          {state.showSuccessPage && <SuccessPage callback={onClickContinueSuccessPage} texts={state.responseResult} />}
          {state.showFGErrorPage && (
            <ErrorPage info={getErrorDefintions(state.responseResult.errorCode || 'genericError')} />
          )}
        </Layout>
      )}
    </>
  );
};

/* ******************************************************************************* */
const LivenessPage = ({ getToken }: PageProps) => {
  const { infoUser, timeStamp, getUser, getCompanyDetails } = useContext(CreditcardContext);
  const allValid = [Object.entries(infoUser).length, timeStamp?.length ?? 0].every(isGreaterThan(0));
  const token: string = getToken();
  useEffect(() => {
    if (!token) {
      navigateToError()?.();
    } else {
      getUser?.(token);
      getCompanyDetails?.();
    }
  }, []);

  useEffect(() => {
    if (allValid) {
      const state = { expired: { state: { messageMiddleFrom: pages.USER.UPDATE_USER_DETAIL } } };
      redirectWhenStatusPageIsNotValid(infoUser, timeStamp, state);
    }
  }, [infoUser, timeStamp]);

  const render = [allValid, !!diff(timeStamp ?? '', infoUser?.expirationTime ?? ''), !infoUser.status].every(isTrue);
  return <LivenessVUSecurity token={token} render={render} />;
};

export default LivenessPage;
