import { useStaticQuery, graphql } from 'gatsby';
import React, { useContext } from 'react';
import Button from '../../../../../components/287634/button/button';
import { LangContext } from '../../../../../context/lang.context';
import { CompanyContext } from '../../../../../context/company.context';
import { returnWhatsapp } from '../../../../../utils/browser';
import { ErrorDefinitions } from '../../../vu-security/models';
import { Stepper } from '../../../../../components/287634/stepper/stepper';
import { H2, P } from '../../../../../components/287634/typography';

interface Props {
  info: ErrorDefinitions;
}

export const ErrorPage = ({ info }: Props) => {
  const { title_1, description_1, imageSrc, showAlert, cta } = info;
  const { getPublicURL } = useContext(CompanyContext);
  const { t } = useContext(LangContext);
  const data = useStaticQuery(graphql`
    query {
      allI18NJson {
        nodes {
          locale
          clientId
          RETRY
          RETURN_WHATSAPP
          LIVENESS_VU {
            SOP {
              MSG {
                DEFAULT_TITLE_3
                FG_ALERT_MSG
              }
            }
          }
        }
      }
    }
  `);

  return (
    <section className="flex flex-auto flex-col items-center justify-between text-primary">
      <section className="relative flex flex-col items-center">
        <Stepper step={2} />
        <H2 className="mx-5 mb-5 mt-[68px] text-center">{title_1 || t(data).LIVENESS_VU.SOP.MSG.DEFAULT_TITLE_3}</H2>
      </section>
      <section className="flex flex-col items-center">
        <img
          className="m-auto mb-5"
          src={imageSrc || getPublicURL(`/theme/assets/images/error/generic-error.svg`)}
          alt="Liveness Error"
        />
        <P className="mx-14 mb-11 text-center text-red">{description_1}</P>
      </section>
      {showAlert && (
        <div className="mx-6 mt-6 mb-5 flex items-center rounded-md bg-red-o-01 p-3 text-center text-sm leading-tight text-gray-800">
          <img
            src={getPublicURL(`/theme/assets/images/liveness-vu/exclamation-triangle-red.svg`)}
            className="mx-3 mt-2 mb-3"
            alt="Triangle exclamation"
          />
          <p className="text-left leading-tight">{t(data).LIVENESS_VU.SOP.MSG.FG_ALERT_MSG}</p>
        </div>
      )}
      <section className="flex flex-col items-center">
        <Button
          className="!font-regular !appearance-none !border-none !font-body"
          type="button"
          onClick={() => {
            returnWhatsapp();
          }}
        >
          {cta || t(data).RETURN_WHATSAPP}
        </Button>
      </section>
    </section>
  );
};
